import "./polyfill";
import './style/style.scss';
import 'budicons2/Outline/style.css';

import objectFitImages from 'object-fit-images';

objectFitImages();

document.addEventListener("DOMContentLoaded", () => {

    //Dropdown
    const dropdownButtons = Array.from(document.querySelectorAll("[data-js='dropdownButton']"));
    dropdownButtons.forEach(btn => {
        btn.addEventListener("click", (event) => {
            const currentTarget = event.currentTarget;
            const jsId = currentTarget.nextElementSibling.getAttribute("data-js");

            if(jsId && jsId === "dropdownContent"){
                currentTarget.nextElementSibling.classList.toggle("d-none");
                currentTarget.nextElementSibling.classList.toggle("article-section__dropdown-content--expanded");
                currentTarget.classList.toggle("article-section__dropdown-button--toggled");

                const budicon = currentTarget.querySelector(".budicon");
                if(budicon){
                    budicon.classList.toggle("budicon-arrow-bottom");
                    budicon.classList.toggle("budicon-arrow-top");
                }
            }
        });
    });

    //Imgtext
    const imgTexts = Array.from(document.querySelectorAll("[data-js='imgText']"));
    imgTexts.forEach(imgText => {
        const rect = imgText.previousElementSibling.getBoundingClientRect();

        if(imgText.previousElementSibling.nodeName === "IMG"){
            imgText.style.width = rect.width + "px";
        }
    });
});